<template>
    <div class="col-lg-4">
      <div class="card">
        <div class="card-body">
          <div class="card-title">
            <div class="chip">
              <img src="/assets/images/avatars/avatar-3.png" alt="Contact Person">{{name}}
            </div>
          </div>
          <p class="card-text">{{startTime}} to {{endTime}}<br/>{{summaryTime}} (-{{deductions}} Minutes)</p>	
          <a href="javascript:;" @click="$emit('confirm')" class="btn btn-success">Confirm</a><a href="javascript:;" class="btn btn-light ms-2" data-bs-toggle="modal" :data-bs-target="'#editDeductionsModal' + id">Edit</a><a href="javascript:;" @click="$emit('deny')" class="btn btn-danger ms-2">Deny</a>
        </div>
      </div>

      <div class="modal fade" :id="'editDeductionsModal' + id" tabindex="-1" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Manage Timesheet Entry</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>Please note this will override the currently selected Type for all selected nodes.</p>
              <div class="form-group mb-2">
                <label class="form-label">Deductions</label>
                <input class="form-control" type="number" v-model="d" placeholder="Deductions">
              </div>
              <div class="form-group">
                <label class="form-label">Start</label>
                <input type="datetime-local" v-model="s" class="form-control">
              </div>
              <div class="form-group">
                <label class="form-label">End</label>
                <input type="datetime-local" v-model="e" class="form-control">
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :id='"closeEditDeductionsModal" + id'>Close</button>
              <submit-button type="button" :background="'btn btn-primary'" @click="$emit('edit', d, s, e)" data-bs-dismiss="modal" text="Save changes"></submit-button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import {DateTime} from 'luxon';
import SubmitButton from '../SubmitButton.vue';
export default {
  name: 'Engineer',
  components: {
    SubmitButton
  },
  props: {
    id: Number,
    name: String,
    start: Number,
    deductions: Number,
    end: Number
  },
  data(){
    return {
      d: 0,
      s: 0,
      e: 0
    }
  },
  mounted(){
    this.d = this.deductions;
    this.s = DateTime.fromSeconds(this.start).toISO().split('.')[0];
    this.e = DateTime.fromSeconds(this.end).toISO().split('.')[0];
  },
  computed: {
    startTime: function(){
      return DateTime.fromSeconds(this.start).toFormat('dd/MM/yyyy HH:mm');
    },
    endTime: function(){
      return DateTime.fromSeconds(this.end).toFormat('dd/MM/yyyy HH:mm');
    },
    summaryTime: function(){
      let diff = DateTime.fromSeconds(this.end).diff(DateTime.fromSeconds(this.start), ["hours", "minutes"]).toObject();
      return `${diff.hours} Hours ${diff.minutes} Minutes`;
    }
  }
}
</script>