<template>
    <button class="btn" v-bind:class="background" v-bind:disabled="clicked">
        <span v-if="!clicked">{{text}}</span>
        <div v-else class="spinner-border" role="status"> <span class="visually-hidden">Loading...</span></div>
    </button>
</template>

<script>
export default {
name: "SubmitButton",
    props: {
        background: String,
        text: String,
        clicked: Boolean,
    }
}
</script>

<style scoped>

</style>
