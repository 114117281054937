<template>
	<div class="card">
		<div class="card-body">
			<ul class="nav nav-pills mb-3" role="tablist">
				<li class="nav-item" role="presentation">
					<a class="nav-link active" data-bs-toggle="pill" href="#tab-pending" role="tab" aria-selected="true">
						<div class="d-flex align-items-center">
							<div class="tab-title">Pending (3)</div>
						</div>
					</a>
				</li>
				<li class="nav-item" role="presentation">
					<a class="nav-link" data-bs-toggle="pill" href="#tab-clocked-in" role="tab" aria-selected="false">
						<div class="d-flex align-items-center">
							<div class="tab-title">Clocked In (11)</div>
						</div>
					</a>
				</li>
				<li class="nav-item" role="presentation">
					<a class="nav-link" data-bs-toggle="pill" href="#tab-not-clocked-in" role="tab" aria-selected="false">
						<div class="d-flex align-items-center">
							<div class="tab-title">Not Clocked In (4)</div>
						</div>
					</a>
				</li>
			</ul>
			<div class="tab-content">
				<div class="tab-pane fade active show" id="tab-pending" role="tabpanel">
					
					<div class="row">
						<engineer v-for="e in pending" :key="e.id" :id="parseInt(e.id)" :deductions="e.deductions || 0" :start="e.start" :end="e.end" :name="e.user_name" @edit="(d, s, end) => {edit(e, d, s, end)}" @confirm="confirm(e)" @deny="deny(e)"></engineer>
					</div>

				</div>
				<div class="tab-pane fade" id="tab-clocked-in" role="tabpanel">
					<div class="row">
						<active-engineer v-for="e in active" :key="e.id" :start="e.start" :name="e.user_name"></active-engineer>
					</div>
				</div>
				<div class="tab-pane fade" id="tab-not-clocked-in" role="tabpanel">
					<div class="row">
						<inactive-engineer :last="1" :name="'Andrew Dodds'"></inactive-engineer>
						<inactive-engineer :last="1" :name="'John Garnett'"></inactive-engineer>
						<inactive-engineer :last="1" :name="'Simon \'The Pensioner\' Dunkerly'"></inactive-engineer>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ActiveEngineer from '../../../components/timesheets/ActiveEngineer.vue'
import Engineer from '../../../components/timesheets/Engineer.vue'
import InactiveEngineer from '../../../components/timesheets/InactiveEngineer.vue'
import axios from 'axios';
import {DateTime} from 'luxon';
export default {
  components: { Engineer, ActiveEngineer, InactiveEngineer },
	name: 'Timesheets',
	data(){
		return {
			pending: [],
			active: [],
      current: null
		}
	},
	mounted(){
		this.loadTimesheets();
	},
	methods: {
		loadTimesheets(){
			axios.get(`${process.env.VUE_APP_API_URL}/v1/timesheets`)
			.then(response => {
				this.pending = response.data.pending;
				this.active = response.data.active;
			})
			.catch(error => {
				this.$error("Failed to load timesheets.", error);
			});
		},
    confirm(t){
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/timesheets/${t.id}/verify`)
      .then(() => {
        this.pending = this.pending.filter((p) => {
          return p.id !== t.id;
        });
      })
      .catch(error => {
        this.$error("Failed to confirm timesheet.", error);
      });
    },
    edit(t, d, s, e){
      t.deductions = parseInt(d);
      t.start = parseInt(DateTime.fromISO(s).toSeconds());
      t.end = parseInt(DateTime.fromISO(e).toSeconds());
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/timesheets/${t.id}`, {
        start: t.start,
        end: t.end,
        deductions: t.deductions
      })
      .then(() => {
        this.$success("Updated timesheet");
      })
      .catch(error => {
        this.$error("Faild updating timesheet", error);
      })
    },
    deny(t){
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/timesheets/${t.id}`)
      .then(() => {
        this.pending = this.pending.filter((p) => {
          return p.id !== t.id;
        });
      })
      .catch(error => {
        this.$error("Failed to deny timesheet.", error);
      });
    }
	}
}
</script>