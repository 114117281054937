<template>
    <div class="col-lg-4">
      <div class="card">
        <div class="card-body">
          <div class="card-title">
            <div class="spinner-grow spinner-grow-sm text-success" role="status"> <span class="visually-hidden">Loading...</span></div>
            <div class="chip ms-2">
              <img src="/assets/images/avatars/avatar-3.png" alt="Contact Person">{{name}}
            </div>
          </div>
          <p class="card-text">Started {{startTime}}<br/>{{summaryTime}}</p>
        </div>
      </div>
    </div>
</template>
<script>
import {DateTime} from 'luxon';
export default {
  name: 'ActiveEngineer',
  props: {
    name: String,
    start: Number
  },
  data(){
    return {
      now: DateTime.local(),
      interval: null
    }
  },
  mounted(){
    this.now = DateTime.local();
    this.interval = setInterval(() => {
      this.now = DateTime.local();
    }, 1000);
  },
  unmounted(){
    clearInterval(this.interval);
  },
  computed: {
    startTime: function(){
      return DateTime.fromSeconds(this.start).toFormat('dd/MM/yyyy HH:mm');
    },
    summaryTime: function(){
      let diff = this.now.diff(DateTime.fromSeconds(this.start), ["hours", "minutes"]).toObject();
      return `${diff.hours} Hours ${diff.minutes.toFixed(0)} Minutes`;
    }
  }
}
</script>