<template>
    <div class="col-lg-4">
      <div class="card">
        <div class="card-body">
          <div class="card-title">
            <div class="chip">
              <img src="/assets/images/avatars/avatar-3.png" alt="Contact Person">{{name}}
            </div>
          </div>
          <p class="card-text">Last Entry was {{lastTime}}</p>	
        </div>
      </div>
    </div>
</template>
<script>
import {DateTime} from 'luxon';
export default {
  name: 'Engineer',
  props: {
    name: String,
    last: Number
  },
  computed: {
    lastTime: function(){
      return DateTime.fromSeconds(this.last).toFormat('dd/MM/yyyy HH:mm');
    }
  }
}
</script>